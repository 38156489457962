@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&display=swap');
@keyframes vibrate {0%,100% {scale:1}50% {scale: 1.05}}
@keyframes flipUp {0% {transform: scale(0) translate(-50%,0%);transform-origin: 0% 50%}100% {transform: scale(1) translate(-50%,0%);transform-origin: 0% 50%}}
@keyframes ldio-sbr194yombs-o{0%{opacity:1;transform:translate(0 0)}49.99%{opacity:1;transform:translate(92px,0)}50%{opacity:0;transform:translate(92px,0)}100%{opacity:0;transform:translate(0,0)}}
@keyframes ldio-sbr194yombs{0%{transform:translate(0,0)}50%{transform:translate(92px,0)}100%{transform:translate(0,0)}}
:root{--bg:#fff;--fg:#000;--color1: #5586FF;--color2:#0A1A56;--color3:#FCC71E;--color4:#0005;--color5:#eee;--color6:#1b874f;--color7:#33BA87;--invert:invert(1);--full:calc(100vh - 80px);    --colorP: #1D6F42;--secondary-color: #0ebb56;--text-color: #333;--gray-100: #f3f4f6;--gray-200: #e5e7eb;--gray-300: #d1d5db;--gray-400: #9ca3af;--gray-500: #6b7280;}
.n{user-select:none;-moz-user-select:none;-khtml-user-select:none;-webkit-user-select:none;-o-user-select:none}
body{background:var(--bg)!important;color:var(--fg)!important;box-sizing: border-box;overflow-x: hidden;line-height: normal!important}
*{font-family: Rubik;box-sizing: border-box;font-weight: normal}
input[type="radio"] {appearance: none;background-color: #fff;width: 20px;height: 20px;border: 5px solid #555;border-radius: 40px;transition: .3s}
input[type="radio"]:checked {appearance: none;background-color: #FFF;width: 20px;height: 20px;border: 5px solid var(--color1);border-radius: 40px;transition: .3s;animation: vibrate .5s}
nav{position: fixed;display: flex;align-items: center;justify-content: space-between;padding: 10px 30px;background: var(--bg);height: 80px;width:100%;z-index: 20}
nav .logo{display:flex;align-items:center;justify-content:center}
nav .logo img{height:50px;width:auto;cursor: pointer}
nav .headings,nav .tools{display:flex;align-items:center;justify-content:center}
nav .headings ul,nav .tools ul{display:flex;align-items:center}
nav .headings ul a,nav .tools ul a{margin:0 10px;padding:7.5px 10px;border-radius:5px;transition:1s;cursor:pointer;color:var(--fg)}
nav .headings ul a:hover,nav .tools ul a.active,nav .tools ul a:hover{background:var(--color1);transition:.3s;color:#fff}
nav .headings ul a.active{border-bottom:var(--color1) 2px solid;padding:7px 20px}
nav .tools button{background:0 0;border:2px solid var(--color1);color:var(--fg);display:grid;place-items:center;width:40px;height:40px;border-radius:40px;padding:6.5px 0}
nav .tools button i{font-size:23px}
nav .tools button:hover{border-color:var(--color1)!important;background:var(--color1);color:#000!important}
nav .tools .buttonLink{color:var(--fg);padding:10px 15px;margin:0 5px;transition:.3s}
nav .tools .buttonLink:first-child,nav .tools .buttonLink:hover{background:var(--color1);color:#fff;padding:10px 15px;margin:0 5px;transition:.3s}
.lightDarkSwitch{cursor:pointer;overflow:hidden;position:relative;margin-left:10px;width:40px;height:40px;border-radius:40px;border:2px solid var(--color1)}
.lightDarkSwitch div{position:absolute;top:50%;left:50%;transform:translate(100%,-50%);transition:.5s}
.lightDarkSwitch div i{font-size:23px;color:var(--fg)}
.lightDarkSwitch div.active{transform:translate(-50%,-50%);transition:.5s}
nav .menuBar{transition:.5s ease-out right;display:none;z-index:3;position:absolute;top:80px;right:-100%;height:var(--full);background:var(--bg);width:60vw;flex-direction:column;justify-content:start;align-items:center;padding:20px;box-shadow:-10px 20px 20px #0009}
nav .menuToggler{border-radius:5px;padding:5px 10px;display:none;cursor:pointer}
nav .menuToggler i{font-size:28px}
nav .menuBar .logo{margin-bottom:20px}
nav .menuBar .logo img{width:250px;height:auto;cursor:pointer}
nav .menuBar .links{width:95%}
nav .menuBar .links .linkRow{display:flex;align-items:center;width:100%;margin:20px 0}
nav .menuBar .links .linkRow a{font-size:20px;text-align:center;border:2px solid var(--color1);margin:0 10px;padding:10px 20px;border-radius:5px;transition:1s;cursor:pointer;color:var(--fg);width:50%;box-shadow:3px 3px 5px var(--color5)}
nav .menuBar .links .linkRow.expanded.focused a.logout {border: 2px solid #d44!important;background: #d44!important;}
nav .menuBar .links .linkRow.expanded a{width:100%}
nav .menuBar .links .linkRow.expanded.focused a:not(.not){background:var(--color1);color:#fff}
nav .menuBar .languages .lng img{width:30px}
nav .menuBar .languages{display:flex;margin-top:10px}
nav .menuBar .languages .lng{display:flex;margin:0 10px;align-items:center;cursor:pointer}
nav .menuBar .languages .lng h3{font-size:22px;margin-left:10px}
nav .menuBar .darkMode{display:flex;align-items:center}
nav .menuBar .darkMode h3{font-size:22px;margin-right:15px}
nav .menuBar .darkMode button{transform:scale(1.2)}
.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child span a{display: flex;align-items: center}
.toolsX{display:flex;align-items:center}
.toolsX .lang{display:flex;align-items:center}
.toolsX .lang h2{font-size:18px}
.lang{font-weight:500;font-family:Roboto}
.lang img{margin:0 10px;width:30px;height:30px}
footer.footer-distributed{background:var(--color2);box-shadow:0 1px 1px 0 rgba(0,0,0,.12);box-sizing:border-box;width:100%;text-align:left;font:bold 16px sans-serif;padding:55px 50px}
footer .footer-center,footer .footer-left,footer .footer-right{display:inline-block;vertical-align:top}
footer .footer-left{width:35%}
footer img{width:300px;border-radius:15px;padding:10px}
footer .footer-links{color:#fff;margin:20px 0 12px;padding:0}
footer .footer-links a{display:inline-block;line-height:1.8;font-weight:400;text-decoration:none;color:inherit}
footer .footer-company-name{color:#ddd;font-size:14px;font-weight:400;margin:0}
footer .footer-center{width:35%}
footer .iconContainer{display:grid;place-items:center;background-color:var(--color1);color:#fff;font-size:20px;width:38px;height:38px;border-radius:50%;text-align:center;line-height:42px;margin:10px 15px}
footer .footer-center .infoContainer{display:flex;align-items:center}
footer .footer-center p{display:inline-block;color:#fff;font-weight:400;margin:0}
footer .footer-center p span{display:block;font-weight:400;font-size:14px;line-height:2}
footer .footer-center p a{color:#20b2aa;text-decoration:none}
footer .footer-links a:before{content:" -";font-weight:300;font-size:20px;left:0;color:#fff;display:inline-block;padding-right:5px}
footer .footer-links .link-1:before{content:none}
footer .footer-right{width:30%}
footer .footer-company-about{line-height:20px;color:#92999f;font-size:13px;font-weight:400;margin:0}
footer .footer-company-about span{display:block;color:#fff;font-size:14px;font-weight:700;margin-bottom:20px}
footer .footer-social span{display:block;color:#fff;font-size:14px;font-weight:700;margin:20px 0}
footer .footer-social .icons {display:flex;justify-content: start;}
footer .footer-social .icons .iconContainer{margin: 0;margin-right: 15px;}
footer .language{display:flex;margin-top:20px}
footer .language .lng{display:flex;align-items:center;padding:10px;border-radius:10px;margin:10px;cursor:pointer;transition:.25s all ease-in-out}
footer .language .lng.active{background:var(--color1);transition:.25s all ease-in-out}
footer .language .lng img{width:30px;margin-right:20px}
footer .language .lng p{color:#fff}
.bttn,.minBttn{display:flex;align-items:center;justify-content:center;background:var(--color1);color:#fff;cursor:pointer;transform:scale(1);transition:1s;outline:0;border:none}
.bttn{border-radius:100px;height:50px;padding:10px 20px}
.bttn:hover{box-shadow:0 0 20px var(--color1);transition:.3s;color:#fff}
.bttn h4{margin-right:10px!important;font-weight:600;font-size:18px}
.bttn.expanded{width:100%}
.bttn.secondColor:hover{box-shadow:0 0 20px var(--color6)}
.minBttn{border-radius:5px;padding:5px 10px}
.minBttn:hover{box-shadow:0 0 10px var(--color1);transition:.3s}
.bttn h4,.minBttn h4{font-family:Roboto;margin:0}
.minBttn h4{font-weight:400;font-size:18px}
.bttn.outlined,.minBttn.outlined{border:2px solid var(--color1);background:0 0;color:var(--fg);transition:.3s}
.bttn.outlined:hover,.minBttn.outlined:hover{box-shadow:0 0 10px var(--color1),inset 0 0 5px var(--color1);transition:.3s}
.bttn.secondColor,.minBttn.secondColor{background:var(--color6)}
.minBttn.secondColor:hover{box-shadow:0 0 10px var(--color6)}
.bttn.disabled{background: #777;cursor: not-allowed}
.bttn.disabled:hover{box-shadow: none}
.loginForm{background:var(--color2);height:100vh;width:100%;overflow:hidden;position:relative;box-shadow:#0005 -100px 80px 150px 0 inset}
.loginForm .background{position:absolute;top:0;left:0;width:100vw;z-index:0}
.loginForm .background img{width:100%;z-index:0;opacity:.1}
.loginForm .content{z-index:2;position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);background:#031430cc;border-radius:20px;padding:40px 40px 30px 40px;width:60%;box-shadow:rgba(0,0,0,.25) 0 14px 28px,rgba(0,0,0,.22) 0 10px 10px;max-width:800px}
.loginForm .content h1{color:#fff;font-weight:700;text-align:center;margin-bottom:20px;font-size:35px}
.theInput{width:100%;display:flex;align-items:stretch;position:relative;margin:10px 0}
.theInput input{width:100%;border-radius:0;background:#fff2;padding:10px 0;outline:0;color:#fff;font-family:Roboto;font-size:20px}
.theInput.x input{border-radius:initial;border-top-right-radius:10px;border-bottom-right-radius:10px}
.theInput .eyeToggler{display:grid;place-items:center;background:#fff2;border-top-right-radius:10px;border-bottom-right-radius:10px;width:50px;cursor:pointer}
.theInput .iconLabel{display:grid;place-items:center;background:#fff2;border-top-left-radius:10px;border-bottom-left-radius:10px;width:50px}
.theInput i{color:#fff;font-size:18px}
.theInput .iconLabel.x i{transform:translateX(1.5px)}
.loginForm .content .inputs .inputRow{display:flex;justify-content: space-between}
.loginForm .content .inputs .inputRow .inputDiv{width: 100%}
.loginForm .content .inputs .inputRow .inputDiv:first-child{margin-right:20px}
.error{display:flex;color:#b44;align-items:center;margin:10px 0;align-self: flex-start}
.error i{font-size:20px;margin-right:10px}
.error p{font-size:18px;font-weight:700}
.loginForm .content .submitButton{width:100%;display:grid;place-content:center}
.loginForm .content .separator{height:1px;background:#fff;margin-bottom:30px;width:100%}
.loginForm .content h6{position:absolute;color:#fff;text-align:center;margin-top:20px;font-size:18px;bottom:-65px;left:50%;transform:translateX(-50%);width:100%}
.loginForm h6.sub{position:absolute;color:#fff;text-align:center;margin-top:20px;font-size:18px;bottom:80px;left:50%;transform:translateX(-50%);width:100%;font-weight: 400;}
.loginForm h6.sub{position:absolute;color:#fff;text-align:center;margin-top:20px;font-size:18px;bottom:80px;left:50%;transform:translateX(-50%);width:100%;font-weight: 400;}
.loginForm h6.sub a,.loginForm .content a{color:#fff;font-weight:700;margin-top:10px}
.loginForm .content .infoActivity{color: #FFF;font-size: 12px;margin: 10px 0;}
.bigT{font-family:Bitter;text-align:center;margin:40px 0 100px 0;color:var(--color1);font-weight:800}
.adv{display:flex;align-items:center;justify-content:center;width:100%;margin-bottom:80px}
.xCard{display:flex;flex-direction:column;align-items:center;justify-content:center;width:30%}
.xCard .ic{width:100px;height:100px;background:linear-gradient(45deg,var(--color1),var(--color2));border-radius:100px;place-items:center;display:grid}
.xCard .ic img{height:50px}
.xCard h3{margin:20px 0;text-align:center}
.xCard p{width:90%;line-height:25px;text-align:center;font-size:15px}
.sectionTitle{width:100%;text-align:center;margin:40px 0 80px 0;position: relative;}
.sectionTitle h1{font-weight:600;text-transform:uppercase}
.sectionTitle h1 span{font-weight:800;color:var(--color1)}
.sectionTitle.t h1 span{font-weight:800;color:var(--color6)}
.sectionTitle .decoration{width:100%;display:flex;align-items:center;justify-content:center;margin-top:15px}
.sectionTitle .decoration .x{width:30px;height:30px;background:var(--color1);border-radius:40px}
.sectionTitle.t .decoration .x{width:30px;height:30px;background:var(--color6);border-radius:40px}
.sectionTitle .decoration::after,.sectionTitle .decoration::before{content:'';width:20%;height:2px;background:var(--color1);margin-inline:10px}
.sectionTitle.t .decoration::after,.sectionTitle.t .decoration::before{content:'';width:20%;height:2px;background:var(--color6);margin-inline:10px}
.whoAlpha{padding:20vh 0;position:relative;overflow-x: hidden}
.anchor{position:absolute;top:0;left:0}
.whoAlpha .content{display:flex;align-items:stretch;margin-top:20px;height:65vh}
.whoAlpha .content .thumb{width:50%;display:flex;align-items:center;justify-content:center}
.whoAlpha .content .thumb img{width:80%;border-radius:20px;box-shadow:var(--color4) 0 10px 100px -20px,var(--color4) 0 30px 60px -30px}
.whoAlpha .content .desc{width:50%;padding-right:3%;display:flex;flex-direction:column;justify-content:center}
.whoAlpha .content .desc p{font-size:18px;line-height:25px;text-align:justify}
.whoAlpha .content .desc p span{font-weight:600}
.whoAlpha .content .desc p:first-child{margin-bottom:18px}
.whoAlpha .content .desc ul{list-style-type:disc;margin:20px 0 0 20px}
.whoAlpha .content .desc ul li{font-size:18px;line-height:30px}
.whoAlpha .content .desc ul li::marker{color:var(--color1)}
.learnExpert{padding:0 0 100px 0;position:relative;overflow: hidden}
.learnExpert .content{display:flex;align-items:stretch;margin-top:20px;height:65vh}
.learnExpert .content .thumb{width:50%;display:grid;place-items:center;position:relative}
.learnExpert .content .thumb img{width:80%;border-radius:20px;box-shadow:var(--color4) 0 10px 100px -20px,var(--color4) 0 30px 60px -30px}
.learnExpert .content .desc{width:50%;padding-left:3%;display:flex;flex-direction:column;justify-content:center;align-items:center;position:relative}
.learnExpert .content .desc::before{content:'';position:absolute;top:0;left:20%;width:160px;height:100%;transform:skewX(-15deg);border:var(--color3) 7.5px solid;z-index:-1}
.learnExpert .content .desc h1{width:70%}
.learnExpert .content .desc p{width:70%;margin:20px 0}
.learnExpert .content .desc .buttonGet{width:50%}
.learnExpert .content .desc .buttonGet a{width:100%}
.testimonial-quote,.testimonial-wrapp,.testimonial-wrapp .quoted,.testimonial-wrapp .testimonial-photo,.testimonial-wrapp .testimonial-text{position:relative;-webkit-transition:all .4s ease-in;-o-transition:all .4s ease-in;transition:all .4s ease-in}
.testimonial-wrapp,.testimonial-wrapp .testimonial-text{text-align:center}
.testimonial-wrapp .quoted,.testimonial-wrapp .testimonial-photo{display:inline-block}
.testimonial-wrapp .quoted{background:var(--color3);color:#fff;font-size:20px;line-height:50px;height:50px;width:50px;-webkit-border-radius:50%;border-radius:50%;-webkit-transform:translateY(50%);-ms-transform:translateY(50%);-o-transform:translateY(50%);transform:translateY(50%);z-index:1}
.testimonial-wrapp .testimonial-text{width:70%;margin:0 auto;background:var(--color5);padding:4.25rem 2.5rem;-webkit-border-radius:10px;border-radius:10px}
.testimonial-wrapp .testimonial-photo{height:100px;width:100px;margin-top:-50px}
.testimonial-wrapp .testimonial-photo,.testimonial-wrapp .testimonial-photo>img{-webkit-border-radius:50%;border-radius:50%;width:100px}
.testimonial-wrapp:hover .testimonial-text{background:var(--color1);color:#fff}
.testimonial-bg,.testimonial-bg-light{background-position:center center;-webkit-background-size:cover;background-size:cover;background-position:center center;background-attachment:fixed;width:100%}
.carouselIc i{color:var(--fg);font-size:40px}
.quote{background:var(--color1);padding:60px 30px;margin-top:50px;color:#fff;display:flex;justify-content:center}
.quote .content{width:70%}
.quote .content .quoteIcon i{color:var(--color3);font-size:35px;margin:0 5px}
.quote .content h1{margin:20px 0;text-align:justify}
.quote .content .quoteOwner{display:flex;align-items:center}
.quote .content .quoteOwner img{width:100px;border-radius:50%;margin:20px}
.quote .content .quoteOwner div p{margin:5px 0}
.waitingFor{height:300px;display:flex;align-items:center;justify-content:center;flex-direction:column}
.waitingFor h1{margin-bottom:30px}
.topBanner1{height:calc(50vh - 100px);background:var(--color2);display:grid;place-items:center;color:#fff}
.topBanner1 h1{font-size:40px;padding:40px}
.courseContainer{display:flex;flex-direction:column;align-items:stretch;width:350px;height:320px;cursor:pointer;margin:20px}
.courseContainer .thumb img{width:100%;border-radius:20px;transition:.3s}
.courseContainer .details{display:flex;flex-direction:column}
.courseContainer .details .title{margin:20px 0;text-align:center;}
.courseContainer .details .rating{display:flex;justify-content:center}
.courseContainer .details .rating .stars{margin-left:10px}
.courseContainer .details .rating .stars i{color:var(--color3)}
.courseContainer:hover .thumb img{filter:brightness(.5);transition:.3s}
.coursesRow{display:flex;flex-wrap:wrap;padding:30px;justify-content:space-evenly;align-items:center}
.contact{height:100vh;overflow:hidden;padding-top:80px;position: relative}
.contact .pic{height:calc(100vh - 80px);overflow: hidden;display: grid;place-items: center}
.contact .pic img{width:100%}
.contact .det{width:50%;padding-top:8%;position: absolute;top: 50%;left: 50%;height: auto;background: var(--color2);padding: 40px 20px;border-radius: 15px;transform: translate(-50%,-50%)!important;box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;color: #FFF}
.contact .det .topDet{text-align:center}
.contact .det .topDet h1{margin-bottom:10px}
.contact .icons{display: flex;align-items: center;justify-content: center;}
.contact .icons .iconContainer{display:grid;place-items:center;background-color:var(--color1);color:#fff;font-size:20px;width:38px;height:38px;border-radius:50%;text-align:center;line-height:42px;margin:10px 15px}
.bottomC{display:flex;flex-direction:column;height:60%;justify-content:space-evenly;align-items:center}
.bottomC .cRow{display:flex;align-items:center;width:90%;border-radius:15px;height:50px;padding:0 20px;position:relative;cursor:pointer;margin:15px 0}
.bottomC .cRow::after{position:absolute;background:var(--color1);border-radius:30px;width:50px;height:50px;top:0;left:10px;content:'';z-index:0;transition:1s}
.bottomC .cRow:not(:first-child)::after{left:8px}
.bottomC .cRow .iconN{display:grid;place-items:center;color:#fff;position:relative}
.bottomC .cRow:first-child .iconN i{font-size:35px;z-index:1}
.bottomC .cRow:not(:first-child) .iconN i{font-size:25px;z-index:1}
.bottomC .cRow h3{font-size:25px;margin-left:30px;z-index:1;color:#FFF}
.topBanner2{width:100%;height:600px;background:var(--color1);display:flex;align-items:center;justify-content:center;position:relative;margin-bottom:70px}
.topBanner2 .description{margin:0 40px;width:700px}
.topBanner2 .description .tags{display:flex;align-items:center;flex-wrap:wrap}
.topBanner2 .description .tags .tag{background:var(--color2);color:#fff;border-radius:5px;padding:5px 10px;margin:5px;cursor:pointer;box-shadow:rgba(0,0,0,.09) 0 2px 1px,rgba(0,0,0,.09) 0 4px 2px,rgba(0,0,0,.09) 0 8px 4px,rgba(0,0,0,.09) 0 16px 8px,rgba(0,0,0,.09) 0 32px 16px}
.topBanner2 .description .title{color:#fff}
.topBanner2 .description .title h1{margin:20px 0}
.topBanner2 .description .info1{display:flex;margin:20px 0}
.topBanner2 .description .info1 .rating{display:flex;align-items:center;color:var(--color3)}
.topBanner2 .description .info1 .rating p{margin:0 10px}
.topBanner2 .description .info1 .tagX{background:var(--color3);color:#000;border-radius:5px;padding:5px 10px;margin:5px;cursor:pointer;box-shadow:rgba(0,0,0,.09) 0 2px 1px,rgba(0,0,0,.09) 0 4px 2px,rgba(0,0,0,.09) 0 8px 4px,rgba(0,0,0,.09) 0 16px 8px,rgba(0,0,0,.09) 0 32px 16px}
.topBanner2 .description .info2{display:flex}
.topBanner2 .description .info2 p{margin-inline:2.5px;font-size:18px;color:#fff}
.topBanner2 .description .info2 p span{font-weight:600;text-decoration:underline}
.topBanner2 .thumbnail{display:grid;place-items:center;margin-right:40px}
.topBanner2 .thumbnail img{border-radius:20px;width:45vw;transition:.3s;cursor:pointer}
.topBanner2 .thumbnail img:hover{filter:brightness(.5);transition:.3s}
.topBanner2 .la{position:absolute;bottom:0;left:50%;display:flex;width:50vw;transform:translate(-50%,20px)}
.topBanner2 .la .bttn:first-child{flex-direction:row-reverse;background:var(--color2);margin-inline:5px;}
.topBanner2 .la .bttn:last-child{background:var(--color3);margin-inline:5px;color:var(--color2)}
.topBanner2 .la .bttn:first-child h4{margin:0 0 0 10px}
.categoryTitle{width:90%;margin:20px auto;border-bottom:2px solid var(--color1);padding-bottom:5px;color:#5586ff}
.video1st{width: 100%;display: grid;place-items: center;margin-bottom: 50px;}
.viewCourseContent{width:90%;margin:0 auto;border:.5px solid var(--color4);padding:20px}
.viewCourseContent.x{border:none}
.viewCourseContent.z{padding:0}
.viewCourseContent.y{display:flex}
.viewCourseContent.y ul{column-gap: 30px;column-count: 2}
.viewCourseContent.w{border: none;padding: 0;}
.viewCourseContent.w .rowVideo{display: flex;align-items: center;margin: 20px 0;padding-bottom: 10px;width: 100%;justify-content: space-between;}
.viewCourseContent.w .rowVideo:not(:last-child){border-bottom: 2px solid var(--color1);}
.viewCourseContent.w .rowVideo img{width: 25px;}
.viewCourseContent.w .rowVideo p{text-align: center;padding: 0 10px;}
.viewCourseContent p{font-size:18px;line-height:22px;text-align:justify}
.viewCourseContent ul{list-style:disc;font-size:20px;line-height:25px;margin-left:20px}
.viewCourseContent ul li::marker{color:var(--color1)}
.buyThis{padding: 50px 0;width: 80%;margin: 0 auto;}
.newComment{width: 100%;display: flex;flex-direction: column;align-items: center}
.newComment textarea{width: 100%;background:var(--bg);color: var(--fg);border: 2px solid var(--color1);padding: 10px 15px;font-size: 21px;outline: none;border-radius: 5px;height: 200px;margin: 20px 0}
.newComment .ratingOf{align-self: flex-end}
.ratingComment{width:100%;border-bottom:.5px solid var(--color4);padding:20px;margin:20px 0}
.ratingComment .userCred{display:flex;align-items:center;justify-content:space-between}
.ratingComment .userCred .profile{display:flex;align-items:center}
.ratingComment .userCred .profile img{width:60px;margin:20px}
.ratingComment .userCred .rating .stars{color:var(--color3)}
.userDashboard .welcome{display:flex;align-items:center;margin:80px auto;padding:30px;background:var(--color1);justify-content:center;width:60%;border-radius:20px;box-shadow:var(--color4) 0 30px 40px -20px,var(--color4) 0 30px 60px -30px;min-width:800px;position: relative}
.userDashboard .welcome img{height:200px;border-radius:50%;margin-right:50px}
.userDashboard .welcome h1{color:#fff}
.userDashboard .welcome .changePro{position: absolute;bottom: -25px;left: -25px;height: 60px;width: 60px;border-radius: 50%;display: grid;place-items: center;background: var(--color2);cursor: pointer;transition:.3s}
.userDashboard .welcome .changePro:hover{background: var(--color1);transition:.3s}
.userDashboard .welcome .logout{position: absolute;bottom: -25px;left: 45px;height: 60px;width: 60px;border-radius: 50%;display: grid;place-items: center;background: #b44;cursor: pointer;transition:.3s}
.userDashboard .welcome .logout:hover{background: #d44;transition:.3s}
.userDashboard .courses,.userDashboard .infos,.userDashboard .progressDashboard{margin:40px auto;width:95%;border:2px solid var(--color4);padding:30px 20px;border-radius:8.5px}
.userDashboard h2{margin-bottom:20px}
.userDashboard .courses .content{display:flex;flex-wrap:wrap;align-items:stretch;justify-content:center;flex-direction: column;border-left: 2px solid var(--color4)}
.userDashboard .courses .content .viewNewCourse{display: flex;justify-content: center;align-items: center;height: 120px}
.userDashboard .courses .content .courseContainer2{width:100%;margin:10px;position: relative;overflow: hidden;display: flex;height: 150px;align-items: center}
.userDashboard .courses .content .courseContainer2 .deleteButton{background: #b44;width: 40px;height: 40px;border-radius: 50%;position: absolute;top: 13px;left: -50px;color: #FFF;display: grid;place-items: center;z-index: 1;opacity: 0;transition: .3s;cursor: pointer}
.userDashboard .courses .content .courseContainer2 .thumb{position:relative;height:100%;display:flex;place-items:center;overflow:hidden;border-radius: 24px;margin: 0 30px;width: calc(800px / 3)}
.userDashboard .courses .content .courseContainer2 .thumb img{height:100%;transition:.3s;scale:1.1}
.userDashboard .courses .content .courseContainer2:hover .thumb img{filter:brightness(.5);transition:.3s}
.userDashboard .courses .content .courseContainer2:hover .thumb .percentage{opacity:1;transition:.3s}
.userDashboard .courses .content .courseContainer2:hover .deleteButton{opacity: 1;left: 40px;;transition: .3s}
.userDashboard .courses .content .courseContainer2 .thumb .percentage{position:absolute;top:50%;left:50%;transform:translate(-50%,-25%);z-index:2;opacity:0;transition:.3s}
.userDashboard .courses .content .courseContainer2 .thumb .percentage h3{color:#fff;font-weight:800;font-size:30px}
.userDashboard .courses .content .courseContainer2 h5{text-align:start;margin-top:10px;cursor:pointer; font-size: 26px;width: calc(100% - 800px / 3)}
.userDashboard .infos .rowInfo .info{border-top: 2px solid var(--color4);border-bottom: 2px solid var(--color4);padding:20px 0;width: 100%}
.userDashboard .infos .rowInfo .info.X{display:flex;align-items:center;justify-content:center;border-right:none;border-left:none;border-radius:0;margin:10px}
.userDashboard .infos .rowInfo .info.X p{width:25%}
.userDashboard .infos .rowInfo .info.X .y{width:80%;display:grid;place-items:center}
.userDashboard .infos .rowInfo .info.X .y h4.t{width:90%;word-break:break-all}
.userDashboard .rowInfo{display:flex}
.userDashboard .progressDashboard .content{display:flex;align-items:center}
.userDashboard .progressDashboard .content .graph{width:50%;height:100%;display:grid;place-items:center}
.userDashboard .progressDashboard .content .statistics{width:50%;height:100%;display:flex;align-items:stretch;flex-direction:column;justify-content:space-evenly}
.userDashboard .progressDashboard .content .statistics .staticRow{display:flex}
.userDashboard .progressDashboard .content .statistics .staticRow .static{border:2px solid var(--color4);padding:10px;border-radius:10px;width:100%;margin:10px;display:flex;flex-direction:column;justify-content:space-evenly;position:relative;height:140px;box-shadow:0 0 10px var(--color4);text-align:center;overflow:hidden;transition:1s}
.userDashboard .progressDashboard .content .statistics .staticRow .static:hover{background:var(--color1);color:#fff;transition:.3s}
.userDashboard .progressDashboard .content .statistics .staticRow .static i{color:var(--color1);font-size:30px;position:absolute;top:20px;left:20px;transition:.3s}
.userDashboard .progressDashboard .content .statistics .staticRow .static:hover i{color:#fff;transition:.3s}
.userDashboard .progressDashboard .content .statistics .staticRow .static h1{font-size:35px}
.userDashboard .progressDashboard .content .statistics .staticRow .static h3{font-size:18px}
.userDashboard .progressDashboard .alignBottom{margin: 20px auto;width: 50%}
.userDashboard .logOut{width: 35%;height: 50px;text-align: center;margin: 40px auto}
.userDashboard .logOut button{width: 100%;color: #b44;border: 2px solid #b44;height: 100%;border-radius: 20px;transition: .3s;background: var(--bg);display: grid;place-items: center}
.userDashboard .logOut button:hover{background: #d44;transition: .3s;color: #FFF;border: 2px solid #d44}
.coursePlayer{width:100%;display:flex;position:relative}
.coursePlayer .playlist{width:40%;display:flex;flex-direction:column;align-items:center;justify-content:start;height:var(--full);overflow-y:scroll}
.coursePlayer .playlist .videoList{display:flex;align-items:center;justify-content:space-between;padding:10px 30px;transition:.3s;cursor:pointer;width: 100%}
.coursePlayer .playlist .videoList:hover{background:#5585ff3c;transition:.3s}
.coursePlayer .playlist .videoList.focus{background:#5585ff;transition:.3s;color: #FFF}
.coursePlayer .playlist .videoList.done{background:#1a960a3c!important;transition:.3s;color: var(--fg)}
.coursePlayer .playlist .videoList.done:hover{background:#1a960a88!important;transition:.3s}
.coursePlayer .playlist .videoList.focus:not(.done) img{filter: grayscale(100%) brightness(200%)}
.coursePlayer .playlist .videoList.focus.done {background:#1a960ad5!important;color: #FFF}
.coursePlayer .playlist .videoList.focus.done img{filter: grayscale(100%) brightness(200%)}
.coursePlayer .playlist .videoList img{width:35px;margin-right:15px;border-radius:10px}
.coursePlayer .playlist .videoList h2{font-size:18px;width:70%}
.coursePlayer .video{width:60%}
.coursePlayer .videoPlayer{width:100%;display: flex;align-items: center;justify-content: center;padding: 20px}
.videoDescription{padding:0 40px}
.videoDescription p{font-size:18px;line-height:25px;text-align:justify;border-top:2px solid var(--color4);margin-bottom:30px;padding-top:20px}
.courseTitle{color: var(--color1);text-align: center;padding: 40px 0;border-top: 2px solid var(--color1)}
.ldio-sbr194yombs div{position:absolute;width:92px;height:92px;border-radius:50%;top:0px;left:8px}
.ldio-sbr194yombs div:first-child{background:var(--color3);animation:ldio-sbr194yombs 0.8s ease-in-out infinite;animation-delay:-.4s}
.ldio-sbr194yombs div:nth-child(2){background:var(--color2);animation:ldio-sbr194yombs 0.8s ease-in-out infinite;animation-delay:0s}
.ldio-sbr194yombs div:nth-child(3){background:var(--color3);animation:ldio-sbr194yombs-o 0.8s ease-in-out infinite;animation-delay:-.4s}
.loadingio-spinner-dual-ball-ttmb4i7p5jm{width:200px;height:100px;display:inline-block;overflow:hidden;background:0 0}
.ldio-sbr194yombs{width:100%;height:100%;position:relative;transform:translateZ(0) scale(1);backface-visibility:hidden;transform-origin:0 0}
.ldio-sbr194yombs div{box-sizing:content-box}
.loading{display: flex;justify-content: center;align-items: center;height: 100vh;flex-direction: column}
.loading h1{margin-top: 20px;font-size: 28px}
.whyUS,.previousA{overflow-x: hidden}
.ratingOf {display: flex;cursor: pointer}
.ratingOf i{padding: 0 2.5px}
.ratingOf .fa-star {font-size: 2rem;color: var(--color3)}
.ratingOf .fa-regular {color: #ccc}
.topBanner3{width: 100%;height: 300px;background: var(--color2);color: #FFF;display: flex;flex-direction: column;align-items: center;justify-content: center;position: relative;}
.topBanner3 p{background: #11a0ff;padding: 5px 15px;border-radius: 5px;margin: 10px 0 20px 0}
.topBanner3 .onlyPurchases{position: absolute;right: 20px;bottom: 20px;display: flex;align-items: center;}
.topBanner3 .onlyPurchases h6{margin-left: 10px;}
.usersTable{display: flex;flex-direction: column;width: 95%;margin: 50px auto}
.usersTable .xrow{display: flex;align-items: center;height: 50px;border-radius: 5px;border: 1px solid var(--color1);width: 100%;margin: 10px 0;box-shadow: 0px 0px 10px #0003;position: relative;}
.usersTable .xrow:not(:first-child){cursor: pointer}
.usersTable .xrow.hasBuyRequest::before{content: '';width: 15px;height: 15px;background: #f00;border-radius: 20px;position: absolute;left: -7.5px;top: -7.5px;}
.usersTable .xrow div {display: grid;place-items: center}
.usersTable .xrow img{height: 40px;width: 40px}
.usersTable .xrow h3{font-family: Roboto}
.usersTable .xrow h3:first-child{text-align: center}
.usersTable .xrow:nth-child(odd){background: #5586ff88}
.usersTable .xrow :nth-child(1):not(img){width: 5%}
.usersTable .xrow :nth-child(2){width: 20%}
.usersTable .xrow :nth-child(3){width: 30%}
.usersTable .xrow :nth-child(4){width: 20%}
.usersTable .xrow :nth-child(5){width: 20%}
.usersTable .xrow.y {background: #0A0;color: #FFF;}
.usersTable .xrow.y h6{width: 100%;text-align: center;}
.coursesDrop{display: grid;place-items: center}
.coursesDrop select{padding: 10px 20px;font-size: 20px;background: none;border:2px solid var(--color1);width: 100%}
.coursesDrop option{background: var(--bg)}
.coursesDrop option p{font-family: Bitter!important}
.coursesAtt{display: flex;flex-direction: column}
.coursesAtt .co-Att{display: flex;align-items: center;font-size: 20px;justify-content: space-between;margin: 20px 0}
.coursesAtt .co-Att .titleY{display: flex;align-items: center}
.coursesAtt .co-Att h3{font-size: 22px;font-weight: 400}
.coursesAtt .co-Att i{margin: 0 20px;color: var(--color1)}
.coursesAtt .co-Att .deleteBtn{display: flex;align-items: center;}
.coursesAtt .co-Att .deleteBtn i{background: #b44;color: #FFF;padding: 10px;border-radius: 5px;cursor: pointer;transition: .3s}
.coursesAtt .co-Att .deleteBtn i:hover{background: #d44;transition: .3s}
.video-container{position:relative;width:100%;display:flex;justify-content:center;background-color:#000}
.video-container.full-screen,.video-container.theater{max-width:initial;width:100%}
.video-container.theater{max-height:90vh}
.video-container.full-screen{max-height:100vh}
video{width:100%}
.video-controls-container{position:absolute;bottom:0;left:0;right:0;color:#fff;z-index:5;opacity:0;transition:opacity 150ms ease-in-out}
.video-controls-container::before{content:"";position:absolute;bottom:0;background:linear-gradient(to top,#0A1A5688,transparent);width:100%;aspect-ratio:6/1;z-index:-1;pointer-events:none}
.video-container.paused .video-controls-container,.video-container:focus-within .video-controls-container,.video-container:hover .video-controls-container{opacity:1}
.video-container.paused .videoPlayerTitle,.video-container:focus-within .videoPlayerTitle,.video-container:hover .videoPlayerTitle{opacity:1;transition:.3s}
.video-controls-container .controls{display:flex;gap:.5rem;padding:.25rem;align-items:center}
.video-controls-container .controls button{background:0 0;border:none;color:inherit;padding:0;height:30px;width:30px;font-size:1.1rem;cursor:pointer;opacity:.85;transition:opacity 150ms ease-in-out}
.video-controls-container .controls button:hover{opacity:1}
.video-container.paused .pause-icon{display:none}
.video-container:not(.paused) .play-icon{display:none}
.video-container.theater .tall{display:none}
.video-container:not(.theater) .wide{display:none}
.video-container.full-screen .open{display:none}
.video-container:not(.full-screen) .close{display:none}
.volume-high-icon,.volume-low-icon,.volume-muted-icon{display:none}
.video-container[data-volume-level=high] .volume-high-icon{display:block}
.video-container[data-volume-level=low] .volume-low-icon{display:block}
.video-container[data-volume-level=muted] .volume-muted-icon{display:block}
.volume-container{display:flex;align-items:center}
.volume-slider{width:0;transform-origin:left;transform:scaleX(0);transition:width 150ms ease-in-out,transform 150ms ease-in-out}
.volume-container:hover .volume-slider,.volume-slider:focus-within{width:100px;transform:scaleX(1)}
.duration-container{display:flex;align-items:center;gap:.25rem;flex-grow:1}
.video-container.captions .captions-btn{border-bottom:3px solid var(--color1)}
.video-controls-container .controls button.wide-btn{width:50px}
.timeline-container{height:7px;margin-inline:.5rem;cursor:pointer;display:flex;align-items:center}
.timeline{background-color:rgba(100,100,100,.5);height:3px;width:100%;position:relative}
.timeline::before{content:"";position:absolute;left:0;top:0;bottom:0;right:calc(100% - var(--preview-position) * 100%);background-color:#969696;display:none}
.timeline::after{content:"";position:absolute;left:0;top:0;bottom:0;right:calc(100% - var(--progress-position) * 100%);background-color:var(--color1)}
.timeline .thumb-indicator{--scale:0;position:absolute;transform:translateX(-50%) scale(var(--scale));height:200%;top:-50%;left:calc(var(--progress-position) * 100%);background-color:var(--color1);border-radius:50%;transition:transform 150ms ease-in-out;aspect-ratio:1/1}
.timeline .preview-img{position:absolute;height:80px;aspect-ratio:16/9;top:-1rem;transform:translate(-50%,-100%);left:calc(var(--preview-position) * 100%);border-radius:.25rem;border:2px solid #fff;display:none}
.thumbnail-img{position:absolute;top:0;left:0;right:0;bottom:0;width:100%;height:100%;display:none}
.video-container.scrubbing .thumbnail-img{display:block}
.timeline-container:hover .preview-img,.video-container.scrubbing .preview-img{display:block}
.timeline-container:hover .timeline::before,.video-container.scrubbing .timeline::before{display:block}
.timeline-container:hover .thumb-indicator,.video-container.scrubbing .thumb-indicator{--scale:1}
.timeline-container:hover .timeline,.video-container.scrubbing .timeline{height:100%}
.videoPlayerTitle{position:absolute;top:0;left:0;width:100%;color:#fff;z-index:5;opacity:0;transition:.3s}
.videoPlayerTitle::before{content:"";position:absolute;top:0;background:linear-gradient(to bottom,#0a1a56a9,transparent);width:100%;height:80px;z-index:-1;pointer-events:none}
.videoPlayerTitle h1{font-size:25px;padding:10px 20px}
.myCarousel{position: relative;height: calc(100vh - 70px);overflow: hidden;width: 100%;}
.myCarousel .topX{position: absolute;top: 0;left: 0;z-index: 2;width: 100%;height: 100%;background: #0037;display: flex;align-items: center;justify-content: center;flex-direction: column;}
.myCarousel .topX h1{color: #FFF;font-size: 40px;text-align: center;margin-bottom: 20px;}
.myCarousel .imgs {position: relative;width: 100%;height: 100%;}
.myCarousel .imgs img {position: absolute;top: 0;left: 0;width: 100%;height: 100%;transition: 1s;}
.myCarousel .imgs img.show{opacity: 1;transition: 1s;}
.myCarousel .imgs img.hide{opacity: 0;transition: 1s;}
.form-container{width:60vw;height:55vh;background:#031430cc;border-radius:8px;box-shadow:0 0 15px 1px rgba(0,0,0,.4);display:flex;flex-direction:column;position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);z-index:2}
.progressbar{width:400px;height:10px;background-color:#fff;margin-bottom:50px;z-index:2;position:absolute;top:80%;left:50%;transform:translate(-50%,100%)}
.progressbar div{width:33.3%;height:100%;background-color:var(--color1)}
.multiform{width: 100%;height: calc(55vh - 150px);}
.multiform h1{text-align:center;width:90%;padding:5vh 0;margin:0 auto;border-bottom:2px solid #FFF;color: #FFF;}
.form-container .body{width: 100%;height: 100%;padding: 30px;}
.form-container .body .inputs{width: 100%;}
.form-container .ctrl{position: absolute;width: 50px;height: 50px;background: var(--color1);display: grid;place-items: center;border-radius: 50px;bottom: -20px;cursor: pointer;transition: .3s}
.form-container .ctrl:hover{box-shadow: 0px 0px 10px var(--color1);transition: .3s;}
.form-container .ctrl.disabled{background:#555;transition: .3s;box-shadow: none;cursor: not-allowed;}
.form-container .ctrl.done{background:var(--color6);transition: .3s;}
.form-container .ctrl.done:hover{box-shadow: 0px 0px 10px var(--color6);transition: .3s;}
.form-container .ctrl i{color: #FFF;font-size: 25px;}
.form-container .ctrl.next{right: -20px;}
.form-container .ctrl.prev{left: -20px;}
.mf-1 .body{display: flex;align-items: center;justify-content: center;flex-direction: column;}
.mf-5 .body, .mf-2 .body{display: flex;align-items: center;justify-content: center;}
.mf-5 .body .instructions, .mf-2 .body .instructions{width: 60%;}
.mf-5 .body img, .mf-2 .body img{width: 120px;height: 120px;margin-right: 20px;}
.mf-5 .body h6, .mf-2 .body h6{color: #FFF;font-size: 18px;font-weight: 600;margin-bottom: 15px;}
.mf-5 .body input, .mf-2 .body input{color: #FFF;font-size: 18px;}
.mf-6 .body{display: flex;flex-direction: column;justify-content: space-evenly;}
.mf-6 .body{color: #FFF;text-align: center;}
.mf-6 .body h2{font-weight: 400;}
.mf-6 .body h2 span{margin: 20px 0;font-weight: 800;color: var(--color1);}
.mf-6 .body h3{margin: 20px 0;}
.page404{width: 100%;height: 100vh;display: flex;align-items: center;justify-content: center;position: relative;}
.page404 .content{display: flex;align-items: start;flex-direction: column;}
.page404 .content h1{color: var(--color3);font-size: 80px;}
.page404 .content p{font-size: 30px;color: #FFF;}
.page404 img.ill{width: 300px;margin-right: 50px;transform: translateY(80px);}
.page404 .bg{position: absolute;top: 0;left: 0;width: 100vw;height: 100vh;z-index: -1;}
.page404 .bg img{width: 100%;}
.courseToBuy{width:90%;border:.5px solid var(--color1);display:flex;margin:0 auto 20px auto;padding:20px;box-shadow:rgba(50,50,93,.25) 0 50px 100px -20px,rgba(0,0,0,.3) 0 30px 60px -30px;position: relative;}
.courseToBuy .thumbnail{width:30%;min-width: 300px;display: grid;place-items: center;}
.courseToBuy .thumbnail img{height:auto;border-radius:18px}
.courseToBuy .details{margin-left:20px;width:calc(70% - 20px);display:flex;flex-direction:column;justify-content:center;align-items:start}
.courseToBuy .details h1{font-size:28px;margin-bottom:10px}
.courseToBuy .details p{font-size:16px}
.courseToBuy .details .prices{display: flex;margin-top:30px;align-items: center;}
.courseToBuy .details .prices h3{font-size:30px;color:#fff;padding:5px 10px;border-radius:5px}
.courseToBuy .details .prices h3:first-child{background:var(--color1);margin-right: 20px;}
.courseToBuy .details .prices h3:last-child{background:#888;text-decoration: line-through;font-size: 18px;}
.courseToBuy .promo{position: absolute;bottom: -30px;right: -30px;color: #FFF;width: 60px;height: 60px;border-radius: 60px;background: var(--color6);display: grid;place-items: center;}
.courseToBuy .promo p{font-size: 20px;font-weight: 600;}
.buyForm{width:90%;background:var(--bg);border:.5px solid var(--color1);display:flex;flex-direction:column;margin:60px auto 100px auto;padding:30px;box-shadow:rgba(50,50,93,.25) 0 50px 100px -20px,rgba(0,0,0,.3) 0 30px 60px -30px}
.buyForm .inputRow{display:flex;justify-content:space-between;margin-bottom:15px}
.buyForm .inputRow input{width:48%;outline:0;padding:10px;border:1px solid #ccc;border-radius:4px;font-size:16px;background:0 0}
.inputRow select{border-color:#ccc!important}
.buyForm .inputRow input:focus,.inputRow select:focus{border:1px solid var(--color1);box-shadow:0 0 5px var(--color1)}
.buyForm .inputRow div.chakra-select__wrapper{width:48%}
.buyForm .inputRow div.chakra-select__wrapper select option{color:var(--fg);background:var(--bg);font-family:sans-serif;border:none}
.buyForm .chakra-radio-group{display: flex;align-items: center;justify-content: space-between;flex-wrap: wrap;}
.buyForm .educLevel{margin:20px 0 30px 0}
.buyForm .educLevel label{padding: 20px;}
.buyForm p.msg{margin: 30px 0}
.buyForm .agreedInfo {display: flex;align-items: center;}
.buyForm .agreedInfo p{margin-left: 10px;}
.buyForm .agreedInfo .p-checkbox-box{border: 1px solid var(--color1);background: transparent;}
.buyForm .agreedInfo input:checked + .p-checkbox-box{border: 1px solid var(--color1);background: var(--color1);}
.buyForm .submitButton{width: 50%;margin: 0 auto 20px auto;}
.successCoContainer{height: 100vh;display: grid;place-items: center;}
.successContainer{width: 65vw;border-radius: 30px;display: flex;height: 60vh;margin: 0 auto;overflow: hidden;max-height: 700px;min-width: 720px;flex-direction: row;}
.successContainer .thumb{background: var(--color7);width: 30%;display: flex;align-items: center;justify-content: center;}
.successContainer .thumb img{width:45%;max-width: 200px;}
.successContainer .desc{display: flex;flex-direction: column;justify-content: space-between;padding: 50px;align-items: center;width: 70%;border: 3px solid var(--color7);border-top-right-radius:30px;border-bottom-right-radius:30px;}
.successContainer .desc button{transition: .2s;padding: 10px 20px;border: 2px solid var(--color7);background: none;border-radius: 5px;}
.successContainer .desc button:hover{color: #FFF;background: var(--color7);transition: .2s;}
.successContainer h1{font-weight: 800;}
.successContainer h5{font-weight: 600;}
.successContainer h3{text-align: center;}
.p-menuitem-link {padding: 10px;}
.p-menuitem-link span.p-menuitem-text{margin-left: 10px;}
.p-contextmenu.p-component{overflow: hidden;}
.accordionHeader{display: flex;justify-content: space-between;width: 100%;align-items: center;}
.accordionHeader img{width: 70px;}
.accordionBody .infoRow{display: flex;justify-content: space-between;margin: 5px 0;}
.accordionFooter{display: flex;justify-content: space-between;margin-top: 10px;}
.accordionFooter i{color: #FFF;padding: 10px;border-radius: 5px;cursor: pointer;transition: .3s}
.accordionFooter i:hover{transition: .3s}
.accordionFooter i:first-child{background: #b44;}
.accordionFooter i:first-child:hover{background: #d44;}
.accordionFooter i:last-child{background: var(--color6);}
.accordionFooter i:last-child:hover{background: var(--color7);}
.sticky-button{position:fixed;display:flex;align-items:center;bottom:20px;left:50%;transform:translateX(-50%);background-color:#007bff;color:#fff;border:none;padding:15px 30px;font-size:24px;border-radius:5px;cursor:pointer;box-shadow:0 4px 8px rgba(0,0,0,.2);z-index:1000;transition:.3s;animation: vibrate 1s ease-in-out 0s infinite;}
.sticky-button:hover{background-color:#0056b3}
.sticky-button i{margin-right:10px;}
.bttn.expanded.k{font-weight: 800;font-family: Roboto;}
.statistics{display: flex;align-items: center;flex-wrap: wrap;width: 95%;margin: 50px auto 10px auto;justify-content: space-around;}
.statCard{display: flex;border-radius: 30px;width: 27.5%;height: 130px;align-items: center;padding: 30px;transition: 0.5s ease-in-out;}
.statCard:hover{transform: scale(1.1);transition: .3s ease-in-out;}
.statCard .icon{width: 25%;display: flex;align-items: center;}
.statCard .icon i{font-size: 24px;border: 2px solid #FFF;border-radius: 8px;width: 40px;height: 40px;display: grid;place-items: center;color: #FFF;}
.statCard .content{display: flex;flex-direction: column;align-items: end;width: 75%;color:#FFF}
.landingExcel .header{background-color:var(--color6);color:#fff;display: flex;height: calc(100vh - 80px);padding: 0 50px;justify-content: space-between;}
.landingExcel .header .content{display: flex;flex-direction: column;align-items: start;justify-content: center;}
.landingExcel .header .content h1{font-size:36px}
.landingExcel .header .content p{font-size:18px;margin:20px 0;width: 60%;}
.certificate{display: flex;align-items: center;margin-bottom: 20px;}
.certificate img{width: 40px;margin-right: 20px;}
.certificate p{margin: 0!important;}
.landingExcel .header .thumb{display: grid;place-items: center;}
.landingExcel .header .thumb img{width:80%;max-width:800px;margin-top:20px;border-radius:8px}
.landingExcel .cta-button{background-color:#baf3d4;color:var(--color6);padding:15px 30px;border:none;border-radius:8px;cursor:pointer;font-size:18px;transition:.3s;}
.landingExcel .cta-button:hover{scale: 1.1;transition: .3s;}
.landingExcel .cta-button.x{background: var(--color6);color: #FFF;}
.landingExcel section{padding:0 20px;margin-bottom:20px}
.landingExcel h2{color:var(--color6);font-size:28px;}
.landingExcel ul{list-style-type:none;padding:0}
.landingExcel ul li{background-color:#baf3d4;margin:10px 0;padding:10px 15px;border-radius:8px;color: #000;}
.landingExcel .testimonials .testimonial{display:flex;flex-direction:column;align-items:center;margin:20px 0}
.landingExcel .testimonials img{width:80px;height:80px;border-radius:50%;margin-bottom:10px}
.landingExcel .testimonials p{font-style:italic;margin:10px 0}
.landingExcel .testimonials span{color:var(--color6)}
.landingExcel .faq .title {display: flex;width: 100%;padding-bottom: 10px;border-bottom: 2px solid var(--color6);align-items: center;justify-content: center;margin-bottom: 20px;}
.landingExcel .faq .title img{width: 60px;margin-right: 15px;}
.landingExcel .faq .title h2{font-size: 45px;margin-bottom: 5px;}
.landingExcel .faq .content{display: flex;align-items: center;padding: 20px;}
.landingExcel .faq .content .questions{width: 60%;}
.landingExcel .faq .content .questions .question{margin:15px 0;padding: 10px 20px 10px 15px;border-radius: 16px;display: flex;align-items: center;justify-content: space-between;height: 50px;width: 100%;box-shadow: 0px 0px 5px var(--color4);overflow: hidden;transition:.3s;}
.landingExcel .faq .content .questions .question h3:last-child{position: relative;color: #FFF;}
.landingExcel .faq .content .questions .question h3:last-child::after{content:'';background: var(--color6);width: 40px;height: 40px;border-radius: 50px;position: absolute;top: 50%;left: 50%;z-index: -1;transform: translate(-50%,-50%);transition:.3s;}
.landingExcel .faq .content .questions .question h3{transition: .3s;}
.landingExcel .faq .content .questions .question:hover h3:first-child{color: #FFF;transition: .3s;}
.landingExcel .faq .content .questions .question:hover {background: var(--color6);transition: .3s;}
.landingExcel .faq .content .questions p{padding: 0 15px;}
.landingExcel .faq .content .questions h3{font-size:22px;color:var(--color6);}
.landingExcel .faq .content .questionHelp{display: flex;flex-direction: column;align-items: center;width: 40%;}
.landingExcel .faq .content .questionHelp img{width: 300px;margin-bottom: 10px;}
.landingExcel .faq .content .questionHelp p{margin: 10px;}
.landingExcel .faq .content .questionHelp .actions{display: flex;}
.landingExcel .faq .content .questionHelp .actions .bttn{background: var(--color6);margin: 0 5px ;}
.landingExcel .faq .content .questionHelp .actions .bttn h4{margin-left: 10px;margin-right: 0!important;}
.landingExcel .faq .content .questionHelp .actions .bttn:hover{background: var(--color6);box-shadow: 0 0 5px var(--color6);}
.footer{background-color:#333;color:#fff;text-align:center;padding:20px}
.footer p{margin:0;font-size:14px}
.footer a:hover{color: #FFF}
.landingExcel .intro{display: flex;align-items: center;padding: 120px 50px 50px 50px}
.landingExcel .intro .thumb{display: flex;justify-content: end}
.landingExcel .intro .thumb img{width:80%;margin: 0 auto;border-radius:8px}
.landingExcel .intro .content p{margin: 10px 0}
.startRightNow{background:#baf3d4;padding: 30px;display: flex;flex-direction: column;align-items: center;}
.startRightNow h2{color: var(--color6);}
.startRightNow p{margin: 5px 0 20px 0;color: #000;}
.startRightNow .bttn{background: var(--color6);}
.startRightNow .bttn:hover{background: var(--color6);box-shadow: 0 0 20px var(--color6);}
.landingExcel .curriculum{display: flex;margin-bottom: 100px;}
.landingExcel .curriculum .thumb{display: flex;justify-content: end;width: 40%;}
.landingExcel .curriculum .thumb img{width:80%;max-width: 600px;border-radius: 20px;}
.landingExcel .curriculum .content{padding: 20px;width: 60%;}
.landingExcel .curriculum .content button{margin-top: 20px;}
.landingExcel .cards {display: flex;align-items: center;justify-content: space-around;flex-wrap: wrap;margin: 40px 0 60px 0;}
.landingExcel .cards .infoCard{display: flex;flex-direction: column;align-items: center;background: #baf3d4;border-radius: 20px;width: 27.5%;max-width: 700px;height: 320px;padding: 20px;margin: 100px 0 30px 0;transition: 1s;}
.landingExcel .cards .infoCard h2{text-align: center;margin-top: 20px;font-size: 2vw;height: 100px;transition: 1s}
.landingExcel .cards .infoCard:hover{background: var(--color6);transition: .3s;}
.landingExcel .cards .infoCard:hover h2{color: #FFF;transition: .3s}
.landingExcel .cards .infoCard:hover p{color: #FFF;transition: .3s}
.landingExcel .cards .infoCard img{width: 150px;margin-top: -80px;}
.landingExcel .cards .infoCard p{font-size: 18px;color: #000;margin: 10px;text-align: justify;transition: 1s}
.landingExcel .title{text-align: center;color: var(--color6);}
.landingExcel .yourProgram{color:#fff;display: flex;padding: 50px;}
.landingExcel .yourProgram .content{display: flex;flex-direction: column;align-items: start;justify-content: center;width: 50%;}
.landingExcel .yourProgram .thumb{display: grid;place-items: center;}
.landingExcel .yourProgram .thumb img{width:80%;max-width:800px;margin-top:20px;border-radius:8px}
.landingExcel .yourProgram .content li{background: none;color: var(--fg);display: flex;align-items: center;font-size: 18px;}
.landingExcel .yourProgram .content li i{color: var(--color6);margin-right: 20px;}
.whatsappBtn{cursor: pointer;position: fixed;z-index: 20;bottom: 15px;right: 15px;background: #30d14e;width: 60px;height: 60px;border-radius: 20px;display: grid;place-items: center;color: #FFF;}
.whatsappBtn i{color: #FFF;}
.container{max-width:1200px;margin:0 auto;padding:0 20px}
.cta-button1{background-color:var(--colorP);color:#FFF;border:none;padding:10px 20px;border-radius:5px;font-weight:700;cursor:pointer;transition:background-color .3s ease}
.cta-button1:hover{background-color:var(--color7)}
.hero{background:linear-gradient(135deg,var(--color7) -300%,var(--bg) 100%);height:100vh;display:flex;align-items:center}
.hero .container{display:flex;align-items:center;justify-content:space-between}
.hero-content{flex:1;max-width:600px}
.hero h1{font-size:3rem;margin-bottom:1rem;color:var(--color6)}
.hero p{font-size:1.2rem;margin-bottom:2rem;color:var(--fg)}
.cta-buttons{display:flex;gap:20px}
.secondary-button{background:var(--bg);color:var(--colorP);border:2px solid var(--colorP);padding:10px 20px;border-radius:5px;font-weight:700;cursor:pointer;transition:all .3s ease}
.secondary-button:hover{background-color:var(--colorP);color:#FFF}
.hero-image{flex:1;display:flex;justify-content:center}
.hero-image img{max-width:85%;height:auto;border-radius:10px;box-shadow:0 10px 20px rgba(0,0,0,0.1)}
.who-is-this-for{background:var(--bg);padding:80px 0}
.who-is-this-for h2{font-size:2.5rem;color:var(--colorP);margin-bottom:2rem;text-align:center}
.who-is-this-for p{font-size:1.2rem;margin-bottom:2rem;text-align:center}
.who-is-this-for ul{list-style-type:none;padding:0;display:grid;grid-template-columns:repeat(auto-fit,minmax(250px,1fr));gap:20px}
.who-is-this-for li{background:var(--color5);color: var(--fg);padding:20px;border-radius:10px;font-size:1.1rem;text-align:center;box-shadow:0 4px 6px rgba(0,0,0,0.1);transition:transform .3s ease;display: flex;align-items: center;}
.who-is-this-for li:hover{transform:translateY(-5px)}
.curriculum1{background:var(--bg);padding:80px 0}
.curriculum1 h2{font-size:2.5rem;color:var(--colorP);margin-bottom:2rem;text-align:center}
.accordion{max-width:800px;margin:0 auto}
.accordion-item{background:var(--bg);border:1px solid var(--gray-300);border-radius:5px;margin-bottom:10px;overflow:hidden}
.accordion-header{background:var(--color5);color: var(--fg);;width:100%;display:flex;justify-content:space-between;align-items:center;padding:15px 20px;cursor:pointer;font-weight:700;transition:.3s ease;margin: 10px 0;border-radius: 10px;}
.accordion-content{padding:20px;background-color:#FFF}
.testimonials{background:var(--bg);padding:80px 0}
.testimonials h2{font-size:2.5rem;color:var(--colorP);margin-bottom:2rem;text-align:center}
.testimonial-grid{display:grid;grid-template-columns:repeat(auto-fit,minmax(300px,1fr));gap:30px}
.testimonial-card{background:var(--color5);color: var(--fg);padding:30px;border-radius:10px;box-shadow:0 4px 6px rgba(0,0,0,0.1);transition:transform .3s ease}
.testimonial-card:hover{transform:translateY(-5px)}
.stars{color:#ffc107;margin-bottom:10px}
.testimonial-content{font-style:italic;margin-bottom:15px}
.testimonial-author{font-weight:700;margin-bottom:5px}
.testimonial-role{color:var(--gray-500)}
.features{list-style-type:none;padding:0;margin-bottom:30px}
.features li{margin-bottom:10px;font-size:1.1rem}
.faq1{background:var(--bg);padding:80px 0}
.faq1 .title{display:flex;align-items:center;justify-content:center;margin-bottom: 30px;}
.faq1 h2{font-size:2.5rem;color:var(--colorP);text-align:center}
.faq1 img{width: 60px;margin-right: 30px;}
.faq-list{max-width:800px;margin:0 auto}
.faq-item{border-bottom:1px solid var(--gray-300)}
.faq-question{display:flex;justify-content:space-between;align-items:center;width:100%;padding:20px 0;background:none;border:none;text-align:left;cursor:pointer;font-weight:700;transition:color .3s ease}
.faq-question:hover{color:var(--colorP)}
.faq-answer{padding:0 0 20px}
.graphs-container{width:100%;max-width:1200px;margin:0 auto;padding:0 20px}
.graphs-container p.sub{font-size:1.2rem;margin-bottom:20px}
.graphs-hero{background:linear-gradient(135deg,var(--color6),var(--color7));color:#fff;text-align:center;height:calc(100vh - 80px);display:flex;align-items:center}
.graphs-landing-page h3:not(.x){margin-bottom:30px;color:var(--colorP);border-bottom:2px solid var(--colorP)}
.graphs-why{display:flex;align-items:center;margin:80px 0}
.graphs-why .content{width:60%;padding:40px 80px}
.graphs-why .content h1{color:var(--colorP);font-size:30px;width:100%;padding-bottom:2.5px;margin-bottom:15px;border-bottom:2px solid var(--colorP)}
.graphs-why .content p{text-align:justify}
.graphs-why .thumbnail{width:50%}
.graphs-why .thumbnail img{width:40vw;border-radius:15px}
.graphs-cta-group{display:flex;justify-content:center;gap:1rem;margin-bottom:2rem}
.graphs-cta-button{background-color:var(--color3);color:#000;border:none;padding:.5rem 1rem;border-radius:4px;cursor:pointer;font-weight:700}
.graphs-cta-button.graphs-secondary{background-color:#fff;color:var(--color6)}
.graphs-features-highlight{background-color:rgba(255,255,255,.1);border-radius:20px;padding:2rem;margin-top:2rem}
.graphs-features-highlight h3{margin-bottom:20px}
.graphs-features-grid{display:flex;gap:1.5rem}
.graphs-feature{display:flex;align-items:center;flex-direction:column;gap:20px}
.graphs-feature-icon{font-size:2rem;color:var(--color3)}
.graphs-content-grid{display:flex;align-items:center;gap:2rem}
.graphs-image-container img{width:60vw;height:auto;border-radius:8px;border:5px solid var(--color7)}
.graphs-benefits-list{list-style-type:none;padding:0}
.graphs-benefits-list li{display:flex;align-items:flex-start;margin-bottom:1rem}
.graphs-icon{width:24px;height:24px;margin-right:.5rem;color:var(--color7)}
.graphs-modules-grid{display:grid;grid-template-columns:1fr;gap:1rem;list-style-type:none;padding:0}
.graphs-curriculum-grid{display:flex;gap:2rem}
.graphs-curriculum-column{width: 50%;}
.graphs-module-list{display:flex;flex-direction:column;gap:1rem}
.graphs-module-item{display:flex;align-items:center;background-color:#f1f3f4;padding:1rem;border-radius:4px}
.graphs-module-number{background-color:var(--color1);color:#fff;width:30px;height:30px;border-radius:50%;display:flex;justify-content:center;align-items:center;margin-right:1rem}
.graphs-cta-banner{background:var(--color1);color:#fff;padding:4rem 0;text-align:center}
.graphs-cta-banner h2{font-size:2rem;margin-bottom:1rem}
.graphs-cta-banner p{font-size:1.2rem;max-width:800px;margin:0 auto 2rem}
.graphs-faq-list{max-width:800px;margin:0 auto}
.graphs-faq-item{margin-bottom:1rem}
.graphs-faq-question{width:100%;text-align:left;background-color:#f1f3f4;border:none;padding:1rem;display:flex;justify-content:space-between;align-items:center;cursor:pointer;font-weight:700}
.graphs-faq-answer{background-color:#fff;padding:1rem;border:1px solid #f1f3f4}
.sectionTitle .anchor{width: 1px;height: 1px;position: absolute;top: -140px;left: 0;}
@media(max-width:1200px){
.topBanner2 .la{width:65vw}
.userDashboard h2{text-align:center}
.userDashboard .progressDashboard .content{flex-direction:column}
.userDashboard .progressDashboard .content .graph,.userDashboard .progressDashboard .content .statistics{width:100%}
.coursePlayer .playlist .videoList p{display: none}
.myCarousel .imgs img {object-fit: cover;object-position: center}
.landingExcel .cards .infoCard{width:100%;margin:50px 0;height:auto!important}
}
@media(max-width:1000px){
.successContainer{flex-direction: column;margin-top: 10px;min-width: auto;width: 90%;height: 500px;}
.successContainer .thumb,.successContainer .desc{width: 100%;}
.successContainer .thumb{height: 400px;}
.successContainer .thumb img{width: auto;height:50%}
.successContainer .desc{padding: 50px 30px;height: 800px;border-top-right-radius: 0;border-bottom-left-radius: 30px;}
nav::after,nav::before{display:none}
nav .menuToggler{display:block}
nav .headings,nav .tools{display:none}
nav .menuBar{display:flex}
nav .menuBar{width:100vw}
footer{flex-direction:column;display:flex;align-items:stretch;padding:20px}
footer img{margin: 0 auto;}
footer .footer-center,footer .footer-left,footer .footer-right{width:100%;margin:20px 0}
footer .footer-company-name,footer .footer-links{text-align:center}
.contact .screen .content,footer .footer-center p{width:80%}
.topBanner{flex-direction:column;height:auto;padding-top:0}
.topBanner .content,.topBanner .thumb{width:100%;display:flex;flex-direction:column}
.topBanner .content{padding: 50px 35px}
.topBanner .content h1{width:100%;padding:30px 0;font-size: 35px;hyphens: auto;word-break: break-word}
.topBanner .content h1 span{font-size: 35px;text-align: center}
.topBanner .thumb{margin:10vh 0}
.whoAlpha{padding:0;height:auto}
.whoAlpha .content{flex-direction:column;height:auto}
.whoAlpha .content .desc,.whoAlpha .content .thumb{width:100%}
.whoAlpha .content .thumb{margin:40px 0}
.whoAlpha .content .desc{padding:40px 20px 0 20px}
.learnExpert .content{flex-direction:column;height:auto}
.learnExpert .content .desc,.learnExpert .content .thumb{width:100%;margin:20px 0}
.learnExpert .content .desc h1,.learnExpert .content .desc p{width:95%}
.learnExpert .content .desc{height:70vh}
.sectionTitle .decoration::after,.sectionTitle .decoration::before{width:35%}
.sectionTitle h1{font-size:4vw}
.testimonial-wrapp .testimonial-text{padding:10px;height:70vh;display:grid;place-items:center;width:85%}
.testimonial-wrapp .testimonial-text p{font-size:22px;line-height:25px;text-align:justify;text-align-last:center}
.quote{padding:30px}
.quote .content{width:100%}
.topBanner1 h1{text-align:center}
.contact{height:100vh}
.contact .pic img{width: auto;height: 100%;}
.contact .det{width:95%;display:grid;place-items:center}
.contact .det .topDet p{text-align:center;width:80%;margin:20px auto}
.bottomC .cRow h3{font-size:22px}
.carousel button{visibility:hidden}
.loginForm .content{width:100%;border-radius: 0}
.topBanner1 h1{font-size:7.5vw}
.loginForm .content .inputs .inputRow{flex-direction:column}
.topBanner2{flex-direction:column;height:auto;padding-bottom:40px}
.topBanner2 .description,.topBanner2 .thumbnail{margin:20px 0;width:100%;padding:40px}
.topBanner2 .la{width:75vw}
.userDashboard .rowInfo{flex-direction:column}
.userDashboard .infos{padding:30px 20px}
.coursePlayer{flex-direction: column-reverse}
.coursePlayer .playlist,.coursePlayer .videoPlayer{width: 100%;height: auto}
.coursePlayer .playlist{border: 2px solid var(--color4);height: 600px}
.coursePlayer .playlist .videoList p{display: block}
.userDashboard .welcome .changePro, .userDashboard .welcome .logout{display: none}
.userDashboard .infos .rowInfo .info.X {margin: 10px 0}
.userDashboard .logOut {width: 55%}
.form-container {width: 80vw;}
.mf-2 .body {flex-direction: column;}
.mf-2 .body img {margin: 0 0 20px 0;}
.mf-2 .body .instructions {width: 100%;text-align: center;}
.landingExcel .yourProgram .content {width: 80%;}
.landingExcel .cards .infoCard h2 {font-size: 3.5vw;}
.landingExcel .faq .content{flex-direction:column;padding:0;margin-bottom:30px}
.landingExcel .faq .content .questionHelp .actions{flex-direction:column;width:100%}
.landingExcel .faq .content .questionHelp .actions .bttn{width:100%;margin:5px 0}
.landingExcel .faq .content .questions{width:100%;margin-bottom:30px}
.landingExcel .faq .content .questionHelp{width:100%;text-align:center}
.landingExcel .faq .content .questions .question{height:auto}
.landingExcel .faq .content .questions .question h3:first-child{width:calc(100% - 50px)}
.landingExcel .faq .content .questions p{padding:10px 15px}
.landingExcel .yourProgram .thumb {width: 50%;}
.hero .container{flex-direction:column}
.graphs-features-grid{grid-template-columns:repeat(4,1fr)}
.graphs-why {flex-direction: column-reverse;}
.graphs-why .thumbnail{display: grid;place-items: center;width: 95%;}
.graphs-why .thumbnail img{width: 60vh;}
.graphs-why .content{width: 95%;padding: 20px;}
.graphs-why .content h1 {text-align: center;}
.graphs-content-grid {flex-direction: column;}
.graphs-curriculum-grid{flex-direction: column;}
.graphs-curriculum-column{width: 100%;}
.graphs-hero{height: auto;}
.graphs-hero h2{margin: 30px 0;}
.graphs-cta-group{flex-direction: column;}
.graphs-features-grid{flex-direction: column;}
}
@media(max-width:800px){
.sectionTitle h1{font-size:5vw}
.adv{flex-direction:column}
.xCard{width:95%;margin:20px 0}
.loginForm .content h1{font-size:7vw}
.topBanner2{margin-bottom:180px}
.topBanner2 .thumbnail img{width:100%}
.topBanner2 .la{flex-direction:column;transform:translate(-50%,150px)}
.topBanner2 .la a{margin:5px 0}
.viewCourseContent.y{flex-direction:column}
.viewCourseContent.y ul{width:100%}
.userDashboard .welcome{flex-direction:column;width:95%;min-width:auto}
.userDashboard .welcome img{margin-right:0;margin-bottom:30px;height: 120px}
.userDashboard .welcome h1{font-size:6vw}
.userDashboard .courses .content{border-left: none}
.userDashboard .courses .content .courseContainer2{width:95%;flex-direction: column;height: auto}
.userDashboard .courses .content .courseContainer2 .thumb{width: 100%;height: 50vw}
.userDashboard .courses .content .courseContainer2 h5 {width: 100%;text-align: center;margin: 20px 0}
.userDashboard .progressDashboard .content .statistics .staticRow .static {margin: 15px}
.userDashboard .progressDashboard .alignBottom{width: 100%}
.userDashboard .progressDashboard .content .graph{width: 100%;display: grid;place-items: start}
.userDashboard .infos .rowInfo .info.X{margin: 10px 0}
.loginForm .background img{height:100vh;width:100vh!important;max-width: 100vh!important}
footer.footer-distributed {padding: 20px}
.courseToBuy{flex-direction: column;}
.courseToBuy .thumbnail,.courseToBuy .details{width: 100%;padding: 20px 10px;margin: 0;text-align: center;align-items: center;}
.courseToBuy .details p{margin-top: 30px;}
.buyForm .inputRow {flex-direction: column;margin: 0;}
.buyForm .inputRow input ,.buyForm .inputRow div.chakra-select__wrapper{width: 100%;margin: 10px 0;}
.educLevel .css-1f0wxn3{flex-direction: column;}
.buyForm .educLevel label {margin: 10px 0;columns: 2;}
.landingExcel .header{flex-direction:column;justify-content:center;padding:20px}
.landingExcel .header .content{width:100%;text-align:center;align-items:center}
.landingExcel .header .content h1{font-size:28px}
.landingExcel .header .content p{font-size:16px;width:100%}
.landingExcel .header .thumb{margin-top:20px}
.landingExcel .header .thumb img{width:100%;max-width:600px}
.landingExcel .intro{flex-direction:column;padding:30px}
.landingExcel .intro .thumb{justify-content:center}
.landingExcel .intro .thumb img{width:100%;margin-bottom:30px}
.landingExcel ul li{margin:20px 0}
.landingExcel .curriculum .content button{width:100%}
.landingExcel .intro .content{text-align:center}
.landingExcel .curriculum{flex-direction:column}
.landingExcel .curriculum .thumb{width:100%;justify-content:center}
.landingExcel .curriculum .content{width:100%;text-align:center}
.landingExcel .curriculum .thumb img{width:100%}
.landingExcel .cards{flex-direction:column;align-items:center}
.landingExcel .cards .infoCard h2{font-size:6vw;height:auto}
.landingExcel .yourProgram{flex-direction:column-reverse;padding:0 30px}
.landingExcel .yourProgram .content{width:100%}
.landingExcel .yourProgram .content li{text-align:justify}
.landingExcel .yourProgram .thumb{width: 100%;}
.landingExcel .yourProgram .thumb img{width:100%}
nav.landingNav{margin-top:20px}
.hero-content{text-align:center;margin-bottom:40px}
.cta-buttons{justify-content:center}
.footer-grid{grid-template-columns:1fr}
.footer-bottom{flex-direction:column;text-align:center}
.social-links{margin-top:20px;justify-content:center}
.graphs-main-nav{display:flex}
.graphs-features-grid{grid-template-columns:repeat(2,1fr)}
.graphs-content-grid{grid-template-columns:repeat(2,1fr)}
.graphs-modules-grid{grid-template-columns:repeat(2,1fr)}
}
@media(max-width:600px){
.sectionTitle h1{font-size:8vw}
.loginForm .content{padding:20px}
.topBanner2 .la{width:90vw}
.topBanner2 .thumbnail{padding:0 20px}
.userDashboard .progressDashboard .content .statistics .staticRow{flex-direction:column}
.userDashboard .progressDashboard .content .graph{display:none}
.userDashboard .progressDashboard .content .statistics .staticRow .static {margin: 15px 0}
.userDashboard .progressDashboard .alignBottom{display: none}
.userDashboard .courses, .userDashboard .infos, .userDashboard .progressDashboard {border: none}
.userDashboard .logOut {width: 85%}
.loginForm .content .separator {margin: 15px 0}
.loginForm .content .submitButton{width: 100%;scale: 0.9;margin-top: 10px}
nav .menuBar .links .linkRow a {font-size: 4.3vw}
nav .menuBar .links {width: 99%}
nav .menuBar {padding:20px 5px}
.courseTitle {padding: 40px 20px;}
.viewCourseContent.x.z{width: 100%;}
.viewCourseContent.x.z .newComment{padding: 0 10px;}
.ratingComment .userCred .profile img {width: 40px;margin: 5px;}
.ratingComment .userCred .profile h4 {font-size: 5vw;}
.courseToBuy .details h3 {width: 100%;}
.theInput input {font-size: 16px;}
.form-container {width: 95vw;}
.form-container .ctrl.prev {left: 10px;}
.form-container .ctrl.next {right: 10px;}
.error p {font-size: 14px;}
.sticky-button{width: 75%;justify-content: center;bottom: 40px;padding: 15px;}
.sticky-button{font-size: 18px;}
.progressbar {width: 60%;}
.faq1 img{width: 40px;}
.faq1 h2{font-size: 5vw;}
.landingExcel .faq .title img{width: 40px;}
.landingExcel .faq .title h2{font-size: 5vw;}
}