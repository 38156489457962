
* {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
}
article, aside, details, figcaption, figure, 
 header, hgroup, menu, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
	margin: 0;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
a{
	text-decoration: none;
	color: initial;
}
a:hover{
	text-decoration: none;
	color: initial;
}
h1,h2,h3,h4,h5,h6,p{
	margin: 0;
}
ul{
	padding: 0;
}